<template>
  <div class="collectionAdmin">
    <div class="collectionAdmin_top">
      <VideoTitle title="合集管理">
        <span class="videoTitleRight" @click="newCollection">新建合集</span>
      </VideoTitle>
    </div>
    <div v-if="listDataAll && listDataAll.length">
      <div v-for="(item, index) in listDataAll" :key="index" class="flex collectionAdmin_conter">
        <div class="left_img">
          <el-image :src="item.compilationsImg">
            <div slot="placeholder" class="image-slot">
              加载中
              <span class="dot">...</span>
            </div>
          </el-image>
        </div>
        <div class="column right">
          <div class="right_top">
            <div class="right_tltle">{{ item.compilationsTitle }}</div>
            <span>合集集数：{{ item.videoNum }}集</span>
          </div>
          <div class="flex right_botton">
            <div class="fcc" @click="seeCollection(item)">
              <img src="../../assets/img/creatorPlatform/see.png" />
              <span>查看合集</span>
            </div>
            <div class="fcc" @click="editCollection(item)">
              <img src="../../assets/img/creatorPlatform/eidt.png" />
              <span>编辑合集</span>
            </div>
            <div class="fcc" @click="delCollection(item)">
              <img src="../../assets/img/creatorPlatform/del.png" />
              <span>删除合集</span>
            </div>
          </div>
        </div>
      </div>
      <Pagination :pageTabel="listQuery" @pagingChange="pagingChange"></Pagination>
    </div>
    <el-empty v-else :image-size="200" description="暂无数据"></el-empty>
    <Dialog v-if="videoDialogObj.dialogVisible" @dialogClose="dialogClose" :videoDialogObj="videoDialogObj">
      <div class="cover_left">
        <span style="float: left;">合集名称</span><el-input v-model="collectionName" placeholder="请输入合集名称"></el-input>
      </div>
      <div class="cover_left">
          <span class="text">上传讲义</span>
          <el-input v-model="file" placeholder="请输入合集名称" id="file" name="file" type="file"></el-input>
      </div>
    </Dialog>
  </div>
</template>

<script>
import VideoTitle from '@/components/public/VideoTitle.vue';
import Dialog from '@/components/public/Dialog.vue';
import { confirm } from '@/utils/public';
import Pagination from '@/components/public/Pagination.vue';
import { compilationsByPage, insertCompilation, updateCompilation, deleteCompilation } from '@/utils/serveApi';
export default {
  name: 'collectionAdmin',
  data() {
    return {
      activeIndex: 0,
      inputSearch: '',
      collectionName: '',
      videoDialogObj: {
        dialogVisible: false,
        title: '新建合集',
        wdith: '580px'
      },
      listDataAll: [],
      listQuery: {
        otherId: this.$store.getters.userData.userId,
        pageNum: 1,
        pageSize: 6,
        total: 0
      },
      listDefaultQurey: {},
      file:"",
    };
  },
  created() {
    this.listDefaultQurey = { ...this.listQuery };
    this.getDataList();
  },
  methods: {
    getDataList() {
      compilationsByPage(this.listQuery).then(res => {
        if (res && res.stateCode == 1){
          const { compilations, total } = res.data;
          this.listDataAll = compilations;
          this.listQuery.total = total;
        }
      });
    },
    pagingChange(o) {
      this.listQuery.pageNum = o.pageNum;
      this.getDataList();
    },
    delCollection({ compilationsId }) {
      confirm('是否删除合集', '提示').then(() =>
        deleteCompilation(compilationsId).then(res => {
          const { data, stateDesc } = res;
          data ? this.insertAndCompilation(res, '删除') : this.$message(stateDesc);
        })
      );
    },
    seeCollection({ compilationsId }) {
      this.$router.push({ name: 'viewCollection', query: { compilationsId: compilationsId } });
    },
    dialogClose(determine) {
      if (determine == 'determine') {
        const collectionName = this.collectionName;
        let formData = new FormData();
        if (collectionName) {
          formData.append('compilationsTitle', this.collectionName);
        } else {
          this.$message('合集名称不能为空');
        }
        let fileObj2 = document.getElementById("file").files[0];
        formData.append('file', fileObj2);
        const { compilationsId, compilationsTitle } = this.videoDialogObj;
        if (compilationsId) { 
          formData.append('compilationsId', compilationsId);
          updateCompilation(formData).then(res => this.insertAndCompilation(res, '编辑'));
        } else {
          insertCompilation(formData).then(res => this.insertAndCompilation(res, '新建'));
        }
      } else {
        this.videoDialogObj.dialogVisible = false;
      }
    },
    insertAndCompilation(res, name) {
      if (res.stateCode) {
        this.$message.success(name + '合集成功');
        this.videoDialogObj.dialogVisible = false;
        this.getDataList();
      }
    },
    editCollection({ compilationsTitle, compilationsId }) {
      this.collectionName = compilationsTitle;
      this.videoDialogObj.title = '编辑合集';
      this.videoDialogObj.compilationsId = compilationsId;
      this.videoDialogObj.dialogVisible = true;
    },
    newCollection() {
      this.collectionName = '';
      this.videoDialogObj.title = '新建合集';
      this.videoDialogObj.compilationsId = '';
      this.videoDialogObj.dialogVisible = true;
    }
  },
  components: { VideoTitle, Dialog, Pagination }
};
</script>
<style lang="scss">
.collectionAdmin {
  &_top {
    .el-input {
      .el-input__inner {
        background: #eef2f5;
        border: 1px solid #c1c1c1;
        border-radius: 22px;
      }
    }
    .videoTitleRight {
      width: 112px;
      height: 40px;
      line-height: 40px;
      background: #ffb600;
      border-radius: 22px;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      margin-right: 40px;
      cursor: pointer;
    }
  }
  .dialog_body {
    text-align: center;
    img {
      width: 300px;
      padding: 40px;
    }
    .el-input {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}
</style>
<style lang="scss" scoped>
.collectionAdmin {
  &_top {
    .el-input {
      width: 574px;
      height: 40px;
      margin-right: 40px;
    }
  }
  &_conter {
    padding: 38px 40px;
    .right {
      padding-left: 24px;
      &_tltle {
        font-size: 20px;
        color: #000000;
      }
      &_top {
        color: #999999;
        .icon_text {
          margin: 10px 0;
          img {
            width: 18px;
            height: 18px;
          }
          span {
            font-size: 18px;
            padding: 0 20px 0 8px;
          }
        }
        & > span {
          font-size: 16px;
        }
        .state {
          margin: 10px 0;
          font-size: 16px;
          & > span {
            color: #ff0000;
          }
        }
      }
      &_botton {
        margin-bottom: 5px;
        & > div {
          width: 118px;
          height: 36px;
          background: #eef2f5;
          border-radius: 21px;
          cursor: pointer;
          margin-right: 24px;
          img {
            width: 16px;
            height: 16px;
          }
          span {
            font-size: 16px;
            color: #222222;
            line-height: 0;
            padding-left: 6px;
          }
        }
      }
    }
    .left_img {
      width: 280px;
      height: 175px;
      background-color: #f5f7fa;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
